import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SEOHead } from '../components/SEOHead';

const NotFound: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <SEOHead
        title={t('pages.notFound.title')}
        description={t('pages.notFound.description')}
        canonicalPath="/404"
        locale={i18n.language}
      />
      <div className="min-h-[70vh] flex items-center justify-center px-4">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
          <p className="text-xl text-gray-600 mb-8">{t('pages.notFound.message')}</p>
          <Link 
            to="/" 
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            {t('common.backToHome')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound; 