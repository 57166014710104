export default {
  header: {
    title: "RODFAIFA",
    vehiclesFound: "{{count}} vehicles found",
    quiz: "Quiz",
    compareEvBrands: "Compare EV Brands",
    userExperiences: "User Experiences",
    contactUs: "Contact Us"
  },
  home: {
    title: "RODFAIFA - Compare Electric Cars in Thailand",
    subtitle: "Welcome to RODFAIFA.com, your premier source for comparing electric cars in Thailand. Find the perfect EV car tailored to your needs with our comprehensive comparison tools."
  },
  filters: {
    search: "Search vehicles...",
    priceRange: "Price Range",
    min: "Min",
    max: "Max",
    batteryCapacity: "Battery Capacity (kWh)",
    vehicleType: "Vehicle Type",
    make: "Make",
    sortBy: "Sort By"
  },
  sorting: {
    "price-asc": "Price: Low to High",
    "price-desc": "Price: High to Low",
    "range-desc": "Range: High to Low",
    "acceleration-asc": "Acceleration: Fastest First"
  },
  comparison: {
    title: "Compare Vehicles",
    clearAll: "Clear All"
  },
  vehicle: {
    compare: "Compare",
    added: "Added",
    specifications: "Specifications",
    range: "Range",
    acceleration: "0-100 km/h",
    battery: "Battery",
    topSpeed: "Top Speed",
    year: "Year",
    price: "Price",
    power: "Power",
    torque: "Torque",
    drive: "Drive",
    seating: "Seating",
    cargo: "Cargo Space"
  },
  types: {
    SUV: "SUV",
    Sedan: "Sedan",
    Hatchback: "Hatchback",
    Truck: "Truck",
    Sports: "Sports Car",
    Van: "Van"
  },
  contact: {
    formTitle: "Contact Us",
    name: "Name",
    email: "Email",
    message: "Message",
    send: "Send Message",
    sending: "Sending...",
    success: "Message sent successfully!",
    error: "Failed to send message. Please try again.",
    sendAnother: "Send Another Message"
  },
  experience: {
    title: "User Experiences",
    addExperience: "Share Experience",
    searchExperiences: "Search experiences...",
    filterByMake: "Filter by Make",
    resultsFound: "{{count}} experiences found",
    shareYourThoughts: "Share Your Thoughts",
    selectVehicle: "Select Vehicle",
    positive: "Positive",
    negative: "Negative",
    yourExperience: "Your Experience",
    sharePlaceholder: "Share your experience with this vehicle...",
    yourName: "Your Name",
    submitting: "Submitting...",
    submit: "Submit Experience",
    noResults: "No experiences found matching your criteria",
    submitSuccess: "Experience shared successfully!",
    submitError: "Failed to submit experience. Please try again.",
    enterAdminKey: "Enter admin key",
    confirmDelete: "Are you sure you want to delete this experience?",
    deleteError: "Failed to delete experience",
    delete: "Delete experience"
  },
  seo: {
    comparePage: {
      title: "Compare {{car1}} vs {{car2}} | EV Comparison Thailand",
      description: "Detailed comparison between {{car1}} and {{car2}}. Compare prices, range, features and specifications in Thailand."
    },
    brandPage: {
      title: "{{brand}} Electric Vehicles in Thailand | Prices & Specs",
      description: "Explore {{brand}} electric vehicles available in Thailand. View prices, specifications, and features.",
      heading: "{{brand}} Electric Vehicles",
      subheading: "{{brand}} offers {{count}} electric vehicles in Thailand, starting from {{minPrice}}. Compare all models and find the perfect EV for you."
    }
  },
  categories: {
    SUV: {
      title: "Best Electric SUVs in Thailand 2024 | Compare Prices & Features",
      description: "Compare all electric SUVs available in Thailand. Find the best EV SUV with detailed specifications, prices, and real owner reviews.",
      metaKeywords: "electric SUV, EV SUV Thailand, best electric SUV 2024"
    },
    Sedan: {
      title: "Electric Sedans in Thailand | Compare EV Sedan Models & Prices",
      description: "Complete guide to electric sedans in Thailand. Compare prices, range, and features of all available EV sedans.",
      metaKeywords: "electric sedan, EV sedan Thailand, Tesla Model 3, BYD Seal"
    },
    // ... other categories ...
  },
  brandComparison: {
    title: "Compare Electric Vehicle Brands in Thailand",
    pageTitle: "{{brand1}} vs {{brand2}} EV Comparison Thailand (2024)",
    pageDescription: "Compare {{brand1}} and {{brand2}} electric vehicles in Thailand. Price comparison, specifications, and features of all available models.",
    heading: "{{brand1}} vs {{brand2}} Comparison",
    subheading: "Compare specifications, prices, and features of all available models",
    detailedComparison: "Detailed Comparison",
    keyDifferences: "Key Differences",
    priceComparison: "Price Comparison",
    rangeComparison: "Range Comparison",
    featuresComparison: "Features Comparison",
    faqTitle: "Frequently Asked Questions",
    relatedComparisons: "Related Comparisons",
    availableModels: "Available Models",
    priceRange: "Price Range",
    specifications: "Specifications",
    price: "Price",
    range: "Range",
    batteryCapacity: "Battery Capacity",
    acceleration: "Acceleration",
    compareModels: "Compare with",
    compareNow: "Compare Now →",
    faq: {
      whichBetter: "Which is better: {{brand1}} or {{brand2}}?",
      whichBetterAnswer: "Both {{brand1}} and {{brand2}} offer compelling electric vehicles. {{brand1}} models range from {{price1}}, while {{brand2}} models range from {{price2}}. The choice depends on your specific needs, budget, and preferences.",
      priceRange: "How do {{brand1}} and {{brand2}} prices compare?",
      priceRangeAnswer: "{{brand1}} models are priced from {{price1}}, while {{brand2}} models start from {{price2}}.",
      warranty: "What warranty do {{brand1}} and {{brand2}} offer?",
      warrantyAnswer: "Both manufacturers offer comprehensive warranty packages. Please check with local dealers for specific terms and conditions."
    },
    errors: {
      invalid: {
        title: "Invalid Comparison",
        description: "Please select valid brands to compare"
      },
      notFound: {
        title: "Brands Not Found",
        description: "One or both brands could not be found"
      }
    },
    priceComparisonText: "{{brand1}} models are priced at an average of {{price1}}, while {{brand2}} models average {{price2}}. The price difference between these brands is {{difference}}.",
    rangeComparisonText: "{{brand1}}'s best range is {{range1}} km, compared to {{brand2}}'s {{range2}} km - a difference of {{difference}} km.",
    featuresComparisonText: "{{brand1}} offers {{specs1}}, while {{brand2}} comes with {{specs2}}."
  },
  common: {
    backToHome: "Back to Home",
    loading: "Loading...",
    error: "Error",
    notFound: "Not Found",
    home: "Home",
    vehicles: "Vehicles",
    upTo: "Up to",
    modelsAvailable: "Models Available",
    models: "models",
    backToVehicles: 'Back to Vehicles',
    brands: 'Brands',
    navigation: {
      brands: "Brands",
      allBrands: "All Brands"
    }
  },
  errors: {
    invalidComparison: "Invalid Comparison",
    invalidComparisonDesc: "Please select two different brands to compare.",
    brandsNotFound: "Brands Not Found",
    brandsNotFoundDesc: "We couldn't find the brands you're looking for. Please try different brands.",
    invalidBrand: 'Invalid Brand',
    brandNotSpecified: 'No brand was specified. Please select a brand from our vehicle list.',
    brandNotFound: 'Brand Not Found',
    brandNotFoundDescription: 'Sorry, we couldn\'t find any vehicles for the brand "{{brand}}". Please check our available brands.',
  },
  // **New Quiz Translations**
  quiz: {
    title: "Find Your Ideal EV Car",
    consentText: "We collect your answers to provide personalized recommendations. Do you agree?",
    agree: "I Agree",
    questionBudget: "What is your budget (in Baht)?",
    questionRange: "What minimum range do you require (in km)?",
    questionUsage: "What is your primary usage?",
    questionSeating: "How many seats do you need?",
    next: "Next",
    recommendations: "Recommended EV Cars for You",
    noRecommendations: "No vehicles match your criteria. Please adjust your preferences.",
    step: "Step {{current}} of {{total}}",
    select: "Select an option",
    usageOptions: {
      city: "City Driving",
      highway: "Highway Driving",
      mixed: "Mixed City and Highway"
    },
    seatingOptions: {
      '2': "2 seats",
      '4': "4 seats",
      '5': "5 seats",
      '7': "7 seats"
    },
    showResults: "Show Recommendations",
    startOver: "Start Over",
    questionType: "What type of vehicle are you looking for?"
  },
  pages: {
    home: {
      title: 'RODFAIFA - Compare Electric Vehicles in Thailand | EV Price & Specs Guide 2024'
    },
    comparison: {
      title: 'Compare {{brand1}} vs {{brand2}} - EV Comparison Thailand'
    },
    experience: {
      title: 'EV Owner Experiences - Real User Reviews'
    },
    contact: {
      title: 'Contact Us | RODFAIFA',
      description: "Get in touch with RODFAIFA. We're here to help with your electric vehicle questions.",
      subtitle: "Have a question about electric vehicles in Thailand? We're here to help!"
    },
    quiz: {
      title: 'Find Your Perfect EV - Quick Quiz'
    },
    electricCars: {
      title: "Electric Cars in Thailand 2024 - Complete Guide",
      subtitle: "Discover the latest electric cars available in Thailand, with detailed comparisons, prices, and specifications. From affordable city cars to premium luxury EVs, find the perfect electric vehicle for your needs.",
      stats: {
        availableModels: "Available Models",
        startingPrice: "Starting Price",
        maxRange: "Max Range",
        brandsAvailable: "Brands Available"
      },
      brands: {
        title: "EV Brands in Thailand",
        description: "Thailand's electric vehicle market has grown significantly, with {{count}} major brands now offering EVs. Compare popular brands like Tesla, BYD, and more to find your ideal electric car.",
        modelsAvailable: "{{count}} models available"
      },
      priceRanges: {
        title: "EV Price Ranges in Thailand",
        budget: {
          title: "Budget EVs (Under ฿1M)",
          description: "Affordable electric vehicles starting under 1 million baht. Perfect for city driving and first-time EV buyers. {{count}} models available in this range."
        },
        midRange: {
          title: "Mid-Range EVs (฿1M - ฿2M)",
          description: "Mid-range electric vehicles priced between 1 million and 2 million baht. Ideal for families and long-distance travel. {{count}} models available in this range."
        },
        premium: {
          title: "Premium EVs (Over ฿2M)",
          description: "Luxury electric vehicles priced over 2 million baht. Perfect for high-end luxury and performance. {{count}} models available in this range."
        }
      }
    },
    brands: {
      title: "Electric Vehicle Brands in Thailand | Compare All EV Makes",
      description: "Compare all electric vehicle brands available in Thailand. View prices, specifications, and features across different manufacturers.",
      heading: "Electric Vehicle Brands",
      modelCount: "{{count}} models",
      priceRange: "Price range: {{min}} - {{max}}"
    },
    vehicles: {
      allVehicles: "All Electric Vehicles",
      foundCount: "{{count}} vehicles found",
      title: "Electric Vehicles in Thailand | Compare All EVs",
      description: "Browse and compare all electric vehicles available in Thailand. Find detailed specifications, prices, and features for every EV model."
    },
    notFound: {
      title: "Page Not Found | RODFAIFA",
      description: "The page you're looking for doesn't exist or has been moved.",
      message: "Sorry, we couldn't find the page you're looking for."
    }
  },
  footer: {
    about: "About RODFAIFA",
    tagline: "Thailand's Premier EV Comparison Platform",
    quickLinks: "Quick Links",
    resources: "Resources",
    social: "Follow Us",
    rights: "All Rights Reserved",
    links: {
      evGuide: "EV Guide Thailand",
      compareBrands: "Compare Brands",
      allVehicles: "All Vehicles",
      evQuiz: "EV Quiz",
      userExperiences: "User Experiences",
      contactUs: "Contact Us"
    }
  }
};
