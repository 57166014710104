// [src/pages/ElectricCarsThailand.tsx](src/pages/ElectricCarsThailand.tsx)
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';
import { Link } from 'react-router-dom';
import { vehicles } from '../data/vehicles';
import { SEOHead } from '../components/SEOHead';
import { Battery, Wallet, Timer, Gauge } from 'lucide-react';

const ElectricCarsThailand: React.FC = () => {
  const { t, i18n } = useTranslation();
  
  const brands = [...new Set(vehicles.map(v => v.brand))];
  const priceRanges = {
    budget: vehicles.filter(v => v.price < 1000000),
    midRange: vehicles.filter(v => v.price >= 1000000 && v.price < 2000000),
    premium: vehicles.filter(v => v.price >= 2000000)
  };
  
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "รถยนต์ไฟฟ้าในประเทศไทย 2024",
    "description": "เปรียบเทียบรถยนต์ไฟฟ้าในประเทศไทย ราคา สเปค และรีวิวจากผู้ใช้จริง",
    "inLanguage": "th-TH",
    // ... other structured data
  };
  
  return (
    <div className="container mx-auto px-4 py-8">
      <SEOHead
        title={t('pages.electricCars.title')}
        description={t('pages.electricCars.description')}
        canonicalPath="/electric-car-thailand"
        locale={i18n.language}
        breadcrumbs={[
          { label: t('common.home'), path: '/' },
          { label: t('pages.electricCars.title'), path: '/electric-car-thailand' }
        ]}
      />
      
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="prose prose-lg max-w-none">
          <h1 className="text-4xl font-bold mb-6">
            {t('pages.electricCars.title')}
          </h1>
          
          <p className="text-xl text-gray-600 mb-8 lead">
            {t('pages.electricCars.subtitle')}
          </p>

          {/* Quick Stats */}
          <div className="grid md:grid-cols-4 gap-6 my-12 not-prose">
            <div className="bg-blue-50 p-6 rounded-xl">
              <Battery className="w-8 h-8 text-blue-600 mb-2" />
              <div className="text-2xl font-bold">{vehicles.length}</div>
              <div className="text-gray-600">{t('pages.electricCars.stats.availableModels')}</div>
            </div>
            <div className="bg-green-50 p-6 rounded-xl">
              <Wallet className="w-8 h-8 text-green-600 mb-2" />
              <div className="text-2xl font-bold">฿{Math.min(...vehicles.map(v => v.price)).toLocaleString()}</div>
              <div className="text-gray-600">{t('pages.electricCars.stats.startingPrice')}</div>
            </div>
            <div className="bg-yellow-50 p-6 rounded-xl">
              <Gauge className="w-8 h-8 text-yellow-600 mb-2" />
              <div className="text-2xl font-bold">{Math.max(...vehicles.map(v => v.range))} km</div>
              <div className="text-gray-600">{t('pages.electricCars.stats.maxRange')}</div>
            </div>
            <div className="bg-purple-50 p-6 rounded-xl">
              <Timer className="w-8 h-8 text-purple-600 mb-2" />
              <div className="text-2xl font-bold">{brands.length}</div>
              <div className="text-gray-600">{t('pages.electricCars.stats.brandsAvailable')}</div>
            </div>
          </div>

          {/* Available Brands */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-6">{t('pages.electricCars.brands.title')}</h2>
            <p className="mb-6">
              {t('pages.electricCars.brands.description', { count: brands.length })}
            </p>
            <div className="grid md:grid-cols-3 gap-6 not-prose">
              {brands.map(brand => (
                <Link 
                  key={brand}
                  to={`/brands/${brand.toLowerCase()}`}
                  className="p-6 border rounded-xl hover:shadow-lg transition-all"
                >
                  <h3 className="text-xl font-semibold mb-2">{brand}</h3>
                  <p className="text-gray-600">
                    {t('pages.electricCars.brands.modelsAvailable', { 
                      count: vehicles.filter(v => v.brand === brand).length 
                    })}
                  </p>
                </Link>
              ))}
            </div>
          </section>

          {/* Price Ranges */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold mb-6">{t('pages.electricCars.priceRanges.title')}</h2>
            <div className="space-y-8">
              <div>
                <h3 className="text-2xl font-semibold mb-4">
                  {t('pages.electricCars.priceRanges.budget.title')}
                </h3>
                <p className="mb-4">
                  {t('pages.electricCars.priceRanges.budget.description', { 
                    count: priceRanges.budget.length 
                  })}
                </p>
              </div>
              <div>
                <h3 className="text-2xl font-semibold mb-4">
                  {t('pages.electricCars.priceRanges.midRange.title')}
                </h3>
                <p className="mb-4">
                  {t('pages.electricCars.priceRanges.midRange.description', { 
                    count: priceRanges.midRange.length 
                  })}
                </p>
              </div>
              <div>
                <h3 className="text-2xl font-semibold mb-4">
                  {t('pages.electricCars.priceRanges.premium.title')}
                </h3>
                <p className="mb-4">
                  {t('pages.electricCars.priceRanges.premium.description', { 
                    count: priceRanges.premium.length 
                  })}
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ElectricCarsThailand;