import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
  title: string;
}

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <div className="min-h-screen bg-gray-50">
      <h1 className="sr-only">{title}</h1>
      {children}
    </div>
  );
};