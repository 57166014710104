import React from 'react';
import { Battery, Gauge, Timer, BanknoteIcon } from 'lucide-react';
import { EVehicle } from '../data/vehicles';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../utils/currency';

interface VehicleCardProps {
  vehicle: EVehicle;
  onCompare: (vehicle: EVehicle) => void;
  isCompared: boolean;
}

export default function VehicleCard({ vehicle, onCompare, isCompared }: VehicleCardProps) {
  const { t, i18n } = useTranslation();
  const [imageError, setImageError] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(true);

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-[1.02]">
      <div className="relative w-full pt-[56.25%]"> {/* 16:9 aspect ratio */}
        <img
          src={!imageError ? vehicle.image : '/fallback-car.jpg'}
          alt={`${vehicle.brand} ${vehicle.name} EV Car in Thailand`}
          loading="lazy"
          onError={() => setImageError(true)}
          onLoad={() => setImageLoading(false)}
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
            imageLoading ? 'opacity-0' : 'opacity-100'
          }`}
        />
        {imageLoading && (
          <div className="absolute inset-0 bg-gray-100 animate-pulse" />
        )}
        <div className="absolute top-2 right-2 z-10">
          <button
            onClick={() => onCompare(vehicle)}
            className={`px-3 py-1 rounded-full text-sm font-medium transition-colors
              ${isCompared
                ? 'bg-blue-600 text-white'
                : 'bg-white/90 text-gray-800 hover:bg-blue-50'
              }`}
          >
            {isCompared ? t('vehicle.added') : t('vehicle.compare')}
          </button>
        </div>
      </div>
      
      <div className="p-4">
        <div className="mb-4">
          <h3 className="text-lg md:text-xl font-bold text-gray-900 break-words">
            {vehicle.brand} {vehicle.name}
          </h3>
          <span className="text-xs md:text-sm text-gray-500 block mt-1">
            {t(`types.${vehicle.type}`)}
          </span>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <div className="flex items-center gap-2">
            <BanknoteIcon className="w-4 h-4 shrink-0 text-gray-500" />
            <span className="text-sm font-medium truncate">
              {formatCurrency(vehicle.price, i18n.language)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Gauge className="w-4 h-4 shrink-0 text-gray-500" />
            <span className="text-sm font-medium">
              {vehicle.range} km
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Timer className="w-4 h-4 shrink-0 text-gray-500" />
            <span className="text-sm font-medium">{vehicle.acceleration}s</span>
          </div>
          <div className="flex items-center gap-2">
            <Battery className="w-4 h-4 shrink-0 text-gray-500" />
            <span className="text-sm font-medium">{vehicle.batteryCapacity} kWh</span>
          </div>
        </div>
      </div>
    </div>
  );
}
