import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { vehicles } from '../data/vehicles';
import type { Locale } from '../i18n/seoConfig';
import { ArrowRight } from 'lucide-react';

interface RelatedComparisonsProps {
  currentBrand1: string;
  currentBrand2: string;
}

export const RelatedComparisons: React.FC<RelatedComparisonsProps> = ({
  currentBrand1,
  currentBrand2
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language as Locale;

  // Get all unique brands and normalize case
  const allBrands = [...new Set(vehicles.map(v => v.brand))];
  
  // Generate related comparisons excluding current brands and self-references
  const relatedComparisons = allBrands
    .filter(brand => 
      brand.toLowerCase() !== currentBrand1.toLowerCase() && 
      brand.toLowerCase() !== currentBrand2.toLowerCase()
    )
    .slice(0, 3)
    .map(brand => {
      // Randomly choose between currentBrand1 and currentBrand2 to compare with
      const compareWith = Math.random() < 0.5 ? currentBrand1 : currentBrand2;
      
      // Always sort brands alphabetically for consistency
      const [brand1, brand2] = [brand, compareWith]
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

      // Get price ranges for both brands
      const brand1Vehicles = vehicles.filter(v => v.brand === brand1);
      const brand2Vehicles = vehicles.filter(v => v.brand === brand2);
      
      const getPriceRange = (vehicles: typeof brand1Vehicles) => {
        const prices = vehicles.map(v => v.price);
        return {
          min: Math.min(...prices).toLocaleString(),
          max: Math.max(...prices).toLocaleString()
        };
      };

      const brand1Prices = getPriceRange(brand1Vehicles);
      const brand2Prices = getPriceRange(brand2Vehicles);
      
      return {
        brand1,
        brand2,
        path: `/${locale}/compare-brands/${brand1.toLowerCase()}-vs-${brand2.toLowerCase()}`,
        brand1Models: brand1Vehicles.length,
        brand2Models: brand2Vehicles.length,
        brand1PriceRange: brand1Prices,
        brand2PriceRange: brand2Prices
      };
    });

  if (relatedComparisons.length === 0) {
    return null;
  }

  return (
    <div className="grid md:grid-cols-3 gap-6">
      {relatedComparisons.map(({ 
        brand1, 
        brand2, 
        path, 
        brand1Models, 
        brand2Models,
        brand1PriceRange,
        brand2PriceRange
      }) => (
        <Link 
          key={`${brand1}-${brand2}`}
          to={path}
          className="group p-6 border rounded-xl hover:shadow-lg transition-all bg-white hover:bg-blue-50"
        >
          <div className="flex items-center justify-between mb-4">
            <div className="flex-1">
              <h3 className="text-lg font-semibold text-gray-900">{brand1}</h3>
              <p className="text-sm text-gray-600">
                {brand1Models} {t('common.models')}
              </p>
              <p className="text-sm text-gray-600">
                ฿{brand1PriceRange.min} - ฿{brand1PriceRange.max}
              </p>
            </div>
            <ArrowRight className="mx-2 text-gray-400 group-hover:text-blue-500" />
            <div className="flex-1 text-right">
              <h3 className="text-lg font-semibold text-gray-900">{brand2}</h3>
              <p className="text-sm text-gray-600">
                {brand2Models} {t('common.models')}
              </p>
              <p className="text-sm text-gray-600">
                ฿{brand2PriceRange.min} - ฿{brand2PriceRange.max}
              </p>
            </div>
          </div>
          <div className="text-sm text-blue-600 group-hover:text-blue-700 font-medium">
            {t('brandComparison.compareNow')}
          </div>
        </Link>
      ))}
    </div>
  );
};