import React from 'react';
import { useTranslation } from 'react-i18next';
import { EVehicle } from '../data/vehicles';
import { formatPrice } from '../utils/currency';

interface ComparisonTableProps {
  brand1Vehicles: EVehicle[];
  brand2Vehicles: EVehicle[];
}

export const ComparisonTable: React.FC<ComparisonTableProps> = React.memo(({ 
  brand1Vehicles, 
  brand2Vehicles 
}) => {
  const { t } = useTranslation();

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="text-left p-3">{t('brandComparison.specifications')}</th>
          {brand1Vehicles.map(v => (
            <th key={v.id} className="text-left p-3">{v.name}</th>
          ))}
          {brand2Vehicles.map(v => (
            <th key={v.id} className="text-left p-3">{v.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-3">{t('brandComparison.price')}</td>
          {[...brand1Vehicles, ...brand2Vehicles].map(v => (
            <td key={v.id} className="p-3">{formatPrice(v.price)}</td>
          ))}
        </tr>
        <tr>
          <td className="p-3">{t('brandComparison.range')}</td>
          {[...brand1Vehicles, ...brand2Vehicles].map(v => (
            <td key={v.id} className="p-3">{v.range} km</td>
          ))}
        </tr>
        <tr>
          <td className="p-3">{t('brandComparison.batteryCapacity')}</td>
          {[...brand1Vehicles, ...brand2Vehicles].map(v => (
            <td key={v.id} className="p-3">{v.batteryCapacity} kWh</td>
          ))}
        </tr>
        <tr>
          <td className="p-3">{t('brandComparison.acceleration')}</td>
          {[...brand1Vehicles, ...brand2Vehicles].map(v => (
            <td key={v.id} className="p-3">{v.acceleration}s</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
});
