import React from 'react';
import { useParams } from 'react-router-dom';
import { vehicles } from '../data/vehicles';
import VehicleCard from '../components/VehicleCard';
import { useTranslation } from 'react-i18next';

const BrandPage: React.FC = () => {
  const { brandId } = useParams();
  const { t } = useTranslation();

  const brandVehicles = vehicles.filter(v => 
    v.brand.toLowerCase() === brandId?.toLowerCase()
  );

  if (brandVehicles.length === 0) {
    return <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold">{t('brand.notFound')}</h1>
    </div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">
        {brandVehicles[0].brand} {t('brand.electricVehicles')}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {brandVehicles.map(vehicle => (
          <VehicleCard
            key={vehicle.id}
            vehicle={vehicle}
            onCompare={() => {}}
            isCompared={false}
          />
        ))}
      </div>
    </div>
  );
};

export default BrandPage; 