import React from 'react';
import { useTranslation } from 'react-i18next';
import { EVehicle } from '../data/vehicles';
import { ComparisonTable } from './ComparisonTable';
import { RelatedComparisons } from './RelatedComparisons';
import { SEOHead } from './SEOHead';
import type { Locale } from '../i18n/seoConfig';
import { Zap, Battery, Gauge, BanknoteIcon, Timer, Car } from 'lucide-react';

interface BrandComparisonContentProps {
  brand1: string;
  brand2: string;
  brand1Vehicles: EVehicle[];
  brand2Vehicles: EVehicle[];
}

export const BrandComparisonContent: React.FC<BrandComparisonContentProps> = ({
  brand1,
  brand2,
  brand1Vehicles,
  brand2Vehicles
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language as Locale;

  const getPriceRange = (vehicles: EVehicle[]) => {
    const prices = vehicles.map(v => v.price);
    return {
      min: Math.min(...prices).toLocaleString(),
      max: Math.max(...prices).toLocaleString()
    };
  };

  const brand1Prices = getPriceRange(brand1Vehicles);
  const brand2Prices = getPriceRange(brand2Vehicles);

  const getMaxRange = (vehicles: EVehicle[]) => {
    return Math.max(...vehicles.map(v => v.range));
  };

  const getMinAcceleration = (vehicles: EVehicle[]) => {
    return Math.min(...vehicles.map(v => v.acceleration));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <SEOHead
        title={t('brandComparison.pageTitle', { brand1, brand2 })}
        description={t('brandComparison.pageDescription', { brand1, brand2 })}
        canonicalPath={`/compare-brands/${brand1.toLowerCase()}-vs-${brand2.toLowerCase()}`}
        locale={locale}
      />

      <article className="max-w-5xl mx-auto">
        {/* Hero Section */}
        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl p-8 mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
            {t('brandComparison.heading', { brand1, brand2 })}
          </h1>
          <p className="text-lg text-gray-600 max-w-3xl">
            {t('brandComparison.subheading', { brand1, brand2 })}
          </p>
        </div>

        {/* Quick Stats Cards */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {[
            { brand: brand1, vehicles: brand1Vehicles, prices: brand1Prices },
            { brand: brand2, vehicles: brand2Vehicles, prices: brand2Prices }
          ].map(({ brand, vehicles, prices }) => (
            <div key={brand} className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="bg-gradient-to-r from-gray-50 to-gray-100 px-6 py-4 border-b">
                <h2 className="text-2xl font-bold text-gray-900">{brand}</h2>
              </div>
              <div className="p-6 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center gap-2">
                    <Car className="w-5 h-5 text-blue-500" />
                    <div>
                      <div className="text-sm text-gray-500">{t('brandComparison.availableModels')}</div>
                      <div className="font-semibold">{vehicles.length}</div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <BanknoteIcon className="w-5 h-5 text-green-500" />
                    <div>
                      <div className="text-sm text-gray-500">{t('brandComparison.priceRange')}</div>
                      <div className="font-semibold">฿{prices.min} - ฿{prices.max}</div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Gauge className="w-5 h-5 text-purple-500" />
                    <div>
                      <div className="text-sm text-gray-500">{t('brandComparison.range')}</div>
                      <div className="font-semibold">
                        {getMaxRange(vehicles)} km
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Timer className="w-5 h-5 text-orange-500" />
                    <div>
                      <div className="text-sm text-gray-500">{t('brandComparison.acceleration')}</div>
                      <div className="font-semibold">
                        {getMinAcceleration(vehicles)}s
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Model Comparison */}
        <section className="mb-12 bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="bg-gradient-to-r from-gray-50 to-gray-100 px-6 py-4 border-b">
            <h2 className="text-2xl font-bold text-gray-900">{t('brandComparison.detailedComparison')}</h2>
          </div>
          <div className="p-6 overflow-x-auto">
            <ComparisonTable 
              brand1Vehicles={brand1Vehicles}
              brand2Vehicles={brand2Vehicles}
            />
          </div>
        </section>

        {/* Key Differences */}
        <section className="mb-12 bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="bg-gradient-to-r from-gray-50 to-gray-100 px-6 py-4 border-b">
            <h2 className="text-2xl font-bold text-gray-900">{t('brandComparison.keyDifferences')}</h2>
          </div>
          <div className="p-6 prose max-w-none">
            <h3 className="text-xl font-semibold mb-4">{t('brandComparison.priceComparison')}</h3>
            <p className="mb-6">
              {t('brandComparison.priceComparisonText', {
                brand1,
                brand2,
                price1: `฿${brand1Prices.min} - ฿${brand1Prices.max}`,
                price2: `฿${brand2Prices.min} - ฿${brand2Prices.max}`,
                difference: Math.abs(
                  parseInt(brand1Prices.min.replace(/,/g, '')) - 
                  parseInt(brand2Prices.min.replace(/,/g, ''))
                ).toLocaleString()
              })}
            </p>
            
            <h3 className="text-xl font-semibold mb-4">{t('brandComparison.rangeComparison')}</h3>
            <p className="mb-6">
              {t('brandComparison.rangeComparisonText', {
                brand1,
                brand2,
                range1: getMaxRange(brand1Vehicles),
                range2: getMaxRange(brand2Vehicles),
                difference: Math.abs(
                  getMaxRange(brand1Vehicles) - 
                  getMaxRange(brand2Vehicles)
                )
              })}
            </p>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="mb-12 bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="bg-gradient-to-r from-gray-50 to-gray-100 px-6 py-4 border-b">
            <h2 className="text-2xl font-bold text-gray-900">{t('brandComparison.faqTitle')}</h2>
          </div>
          <div className="p-6">
            <div className="space-y-6">
              <div className="border-b border-gray-100 pb-6">
                <h3 className="text-xl font-semibold mb-2">
                  {t('brandComparison.faq.whichBetter', { brand1, brand2 })}
                </h3>
                <p className="text-gray-600">
                  {t('brandComparison.faq.whichBetterAnswer', {
                    brand1,
                    brand2,
                    price1: `฿${brand1Prices.min} - ฿${brand1Prices.max}`,
                    price2: `฿${brand2Prices.min} - ฿${brand2Prices.max}`
                  })}
                </p>
              </div>
              <div className="border-b border-gray-100 pb-6">
                <h3 className="text-xl font-semibold mb-2">
                  {t('brandComparison.faq.priceRange', { brand1, brand2 })}
                </h3>
                <p className="text-gray-600">
                  {t('brandComparison.faq.priceRangeAnswer', {
                    brand1,
                    brand2,
                    price1: `฿${brand1Prices.min} - ฿${brand1Prices.max}`,
                    price2: `฿${brand2Prices.min} - ฿${brand2Prices.max}`
                  })}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Related Comparisons */}
        <section className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="bg-gradient-to-r from-gray-50 to-gray-100 px-6 py-4 border-b">
            <h2 className="text-2xl font-bold text-gray-900">{t('brandComparison.relatedComparisons')}</h2>
          </div>
          <div className="p-6">
            <RelatedComparisons 
              currentBrand1={brand1}
              currentBrand2={brand2}
            />
          </div>
        </section>
      </article>
    </div>
  );
};