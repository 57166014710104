import { EVehicle } from '../data/vehicles';

const THB_RATE = 35; // Example exchange rate 1 USD = 35 THB

export const convertToTHB = (usdPrice: number): number => {
  return Math.round(usdPrice * THB_RATE);
};

export const formatCurrency = (price: number, locale: string): string => {
  if (locale === 'en') {
    // Still show in THB, just with English formatting
    return `฿${price.toLocaleString('en-US')}`;
  }
  return `฿${price.toLocaleString('th-TH')}`;
};

/**
 * Formats a number as a Thai Baht price string
 * @param price - The price to format
 * @returns Formatted price string with THB currency symbol
 */
export const formatPrice = (price: number): string => {
  return new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(price);
};

/**
 * Formats a price range from an array of vehicles
 * @param vehicles - Array of vehicles to get price range from
 * @returns Formatted price range string
 */
export const formatPriceRange = (vehicles: EVehicle[]): string => {
  if (!vehicles.length) return '-';
  
  const prices = vehicles.map(v => v.price);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  
  if (minPrice === maxPrice) {
    return formatPrice(minPrice);
  }
  
  return `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`;
};

/**
 * Formats a price as millions of baht
 * @param price - The price to format
 * @returns Formatted price in millions
 */
export const formatPriceInMillions = (price: number): string => {
  const millions = price / 1000000;
  return `${millions.toFixed(2)}M THB`;
};
