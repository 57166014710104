import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';

// These arrays are actually used in the map functions, so we keep them
const vehicleTypes = ['SUV', 'Sedan', 'Hatchback', 'Truck', 'Sports', 'Van'];
const makes = [
  'AION',
  'BYD', 
  'Deepal',
  'Lumin',
  'Neta',
  'Omoda',
  'Ora',
  'Hyundai',
  'Tesla',
  'MG', 
  'BMW',
  'Volvo',
  'Nissan',
  'Volkswagen',
  'Porsche',
  'Mercedes-Benz',
  'XPENG',
  'ZEEKR'
];

interface FiltersProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  priceRange: [number, number];
  setPriceRange: (range: [number, number]) => void;
  batteryRange: [number, number];
  setBatteryRange: (range: [number, number]) => void;
  selectedTypes: string[];
  setSelectedTypes: (types: string[]) => void;
  selectedMakes: string[];
  setSelectedMakes: (makes: string[]) => void;
  sortBy: string;
  setSortBy: (sort: string) => void;
}

export default function Filters({
  searchQuery,
  setSearchQuery,
  priceRange,
  setPriceRange,
  batteryRange,
  setBatteryRange,
  selectedTypes,
  setSelectedTypes,
  selectedMakes,
  setSelectedMakes,
  sortBy,
  setSortBy
}: FiltersProps) {
  const { t } = useTranslation();

  // Add these state variables at the beginning of the component
  const [localPriceMin, setLocalPriceMin] = useState(priceRange[0].toString());
  const [localPriceMax, setLocalPriceMax] = useState(priceRange[1].toString());
  const [localBatteryMin, setLocalBatteryMin] = useState(batteryRange[0].toString());
  const [localBatteryMax, setLocalBatteryMax] = useState(batteryRange[1].toString());

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-6">
      <h2 className="text-xl font-semibold">
        Filter EV Cars Available in Thailand
      </h2>
      <div className="space-y-4">
        {/* Search */}
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={t('filters.search')}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Price Range */}
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">{t('filters.priceRange')}</label>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={localPriceMin}
                onChange={(e) => setLocalPriceMin(e.target.value)}
                onBlur={() => {
                  const value = Number(localPriceMin);
                  if (!isNaN(value)) {
                    setPriceRange([value, priceRange[1]]);
                  }
                }}
                placeholder={t('filters.min')}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
              <span>-</span>
              <input
                type="number"
                value={localPriceMax}
                onChange={(e) => setLocalPriceMax(e.target.value)}
                onBlur={() => {
                  const value = Number(localPriceMax);
                  if (!isNaN(value)) {
                    setPriceRange([priceRange[0], value]);
                  }
                }}
                placeholder={t('filters.max')}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
            </div>
          </div>

          {/* Battery Range */}
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">{t('filters.batteryCapacity')}</label>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={localBatteryMin}
                onChange={(e) => setLocalBatteryMin(e.target.value)}
                onBlur={() => {
                  const value = Number(localBatteryMin);
                  if (!isNaN(value)) {
                    setBatteryRange([value, batteryRange[1]]);
                  }
                }}
                placeholder={t('filters.min')}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
              <span>-</span>
              <input
                type="number"
                value={localBatteryMax}
                onChange={(e) => setLocalBatteryMax(e.target.value)}
                onBlur={() => {
                  const value = Number(localBatteryMax);
                  if (!isNaN(value)) {
                    setBatteryRange([batteryRange[0], value]);
                  }
                }}
                placeholder={t('filters.max')}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              />
            </div>
          </div>

          {/* Sort By */}
          <div className="space-y-1">
            <label className="block text-sm font-medium text-gray-700">{t('filters.sortBy')}</label>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            >
              <option value="price-asc">{t('sorting.price-asc')}</option>
              <option value="price-desc">{t('sorting.price-desc')}</option>
              <option value="range-desc">{t('sorting.range-desc')}</option>
              <option value="acceleration-asc">{t('sorting.acceleration-asc')}</option>
            </select>
          </div>
        </div>

        {/* Vehicle Types */}
        <div className="space-y-1">
          <label className="block text-sm font-medium text-gray-700">{t('filters.vehicleType')}</label>
          <div className="flex flex-nowrap gap-2 overflow-x-auto pb-2 md:flex-wrap">
            {vehicleTypes.map((type) => (
              <button
                key={type}
                onClick={() => {
                  if (selectedTypes.includes(type)) {
                    setSelectedTypes(selectedTypes.filter((t) => t !== type));
                  } else {
                    setSelectedTypes([...selectedTypes, type]);
                  }
                }}
                className={`px-4 py-1.5 rounded-full text-sm whitespace-nowrap ${
                  selectedTypes.includes(type)
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {t(`types.${type}`)}
              </button>
            ))}
          </div>
        </div>

        {/* Makes */}
        <div className="space-y-1">
          <label className="block text-sm font-medium text-gray-700">{t('filters.make')}</label>
          <div className="flex flex-nowrap gap-2 overflow-x-auto pb-2 md:flex-wrap">
            {makes.map((make) => (
              <button
                key={make}
                onClick={() => {
                  if (selectedMakes.includes(make)) {
                    setSelectedMakes(selectedMakes.filter((m) => m !== make));
                  } else {
                    setSelectedMakes([...selectedMakes, make]);
                  }
                }}
                className={`px-4 py-1.5 rounded-full text-sm whitespace-nowrap ${
                  selectedMakes.includes(make)
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {make}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
