export default {
  header: {
    title: "RODFAIFA",
    vehiclesFound: "พบ {{count}} รายการ",
    quiz: "ค้นหารถที่ใช่",
    compareEvBrands: "เปรียบเทียบแบรนด์",
    userExperiences: "ประสบการณ์ผู้ใช้",
    contactUs: "ติดต่อเรา"
  },
  home: {
    title: "RODFAIFA - เปรียบเทียบรถยนต์ไฟฟ้าในประเทศไทย",
    subtitle: "ยินดีต้อนรับสู่ RODFAIFA.com แหล่งข้อมูลชั้นนำสำหรับการเปรียบเทียบรถยนต์ไฟฟ้าในประเทศไทย ค้นหารถไฟฟ้าที่เหมาะกับความต้องการของคุณด้วยเครื่องมือเปรียบเทียบที่ครบครัน"
  },
  filters: {
    search: "ค้นหา",
    priceRange: "ช่วงราคา",
    batteryRange: "ระยะทางวิ่ง",
    min: "ร่ำสุด",
    max: "สูงสุด",
    sortBy: "เรียงตาม",
    vehicleType: "ประเภทรถ",
    brand: "แบรนด์",
    charging: "ความเร็วในการชาร์จ",
    features: "ฟีเจอร์",
    specs: "ข้อมูลจำเพาะ"
  },
  sorting: {
    "price-asc": "ราคา: ต่ำไปสูง",
    "price-desc": "ราคา: สูงไปต่ำ",
    "range-desc": "ระยะทาง: สูงไปต่ำ",
    "acceleration-asc": "อัตราเร่ง: เร็วที่สุดก่อน"
  },
  comparison: {
    title: "เปรียบเทียบ {{brand1}} กับ {{brand2}} รถยนต์ไฟฟ้าในประเทศไทย (2024)",
    description: "เปรียบเทียบรถยนต์ไฟฟ้า {{brand1}} และ {{brand2}} ในประเทศไทย ราคา สเปค และฟีเจอร์ของทุกรุ่น",
    specifications: "ข้อมูลจำเพาะ",
    availableModels: "รุ่นที่มีจำหน่าย",
    priceRange: "ช่วงราคา",
    invalidComparison: "การเปรียบเทียบไม่ถู��ต้อง",
    brandNotFound: "ไม่พบแบรนด์",
    brandNotFoundDescription: "ไม่พบแบรนด์ที่คุณต้องการ กรุณาลองแบรนด์อื่น",
    compareModels: "เปรียบเทียบรุ่น",
    priceComparison: "เปรียบเทียบราคา",
    batteryCapacity: "ความจุแบตเตอรี่",
    range: "ระยะทางวิ่ง",
    acceleration: "อัตราเร่ง",
    charging: "ความเร็วในการชาร์จ",
    features: "ฟีเจอร์",
    specs: "ข้อมูลจำเพาะ"
  },
  vehicle: {
    compare: "เปรียบเทียบ",
    added: "เพิ่มแล้ว",
    specifications: "ข้อมูลจำเพาะ",
    range: "ระยะทาง",
    acceleration: "0-100 กม./ชม.",
    battery: "แบตเตอรี่",
    topSpeed: "ความเร็วสูงสุด",
    year: "ปี",
    price: "ราคา",
    power: "กำลัง",
    torque: "แรงบิด",
    drive: "ระบบขับเคลื่อน",
    seating: "ที่นั่ง",
    cargo: "พื้นที่เก็บ���ภาระ"
  },
  types: {
    SUV: "เอสยูวี",
    Sedan: "รถเก๋ง",
    Hatchback: "แฮทช์แบ็ก",
    Truck: "กระบะ",
    Sports: "รถสปอร์ต",
    Van: "รถตู้"
  },
  experience: {
    title: "ประสบการณ์ผู้ใช้",
    addExperience: "แชร์ประสบการณ์",
    searchExperiences: "ค้นหาประสบการณ์...",
    filterByMake: "กรองตามยี่ห้อ",
    resultsFound: "พบ {{count}} รายการ",
    shareYourThoughts: "แชร์ความคิดเห็นของคุณ",
    selectVehicle: "เลือกรถยนต์",
    positive: "ประสบการณ์ที่ดี",
    negative: "ประสบการณ์ที่ไม่ดี",
    yourExperience: "ประสบการณ์ของคุณ",
    sharePlaceholder: "แชร์ประสบการณ์การใช้งานรถยนต์คันนี้...",
    yourName: "ชื่อของคุณ",
    submitting: "กำลังส่ง...",
    submit: "ส่งประสบการณ์",
    noResults: "ไม่พบประสบการณ์ที่ตรงกับเงือนไขการค้นหา",
    submitSuccess: "แชร์ประสบการณ์สำเร็จ!",
    submitError: "ไม่สามารถส่งประสบการณ์ได้ กรุณาลองใหม่อีกครั้ง",
    enterAdminKey: "กรุณาใส่รหัสผู้ดูแลระบบ",
    confirmDelete: "คุณแน่ใจหรือไม่ที่จะลบประสบการณ์ี้?",
    deleteError: "ไม่สามารถลบประสบการณ์ได้",
    delete: "ลบประสบการณ์"
  },
  common: {
    home: "หน้าแรก",
    vehicles: "รถยนต์",
    backToHome: "กลับสู่หน้าแรก",
    loading: "กำลังโหลด...",
    error: "เกิดข้อผิดพลาด",
    notFound: "ไม่พบข้อมูล",
    upTo: "สูงสุด",
    modelsAvailable: "รุ่นที่มีจำหน่าย",
    models: "รุ่น",
    brands: "แบรนด์",
    backToVehicles: "กลับไปยังรถยนต์",
    selected: "เลือกแล้ว",
    clearSelection: "ล้างการเลือก",
    navigation: {
      brands: "แบรนด์",
      allBrands: "แบรนด์ทั้งหมด"
    }
  },
  brandComparison: {
    title: "เปรียบเทียบแบรนด์รถยนต์ไฟฟ้าในประเทศไทย",
    pageTitle: "เปรียบเทียบ {{brand1}} vs {{brand2}} รถยนต์ไฟฟ้าในประเทศไทย (2024)",
    pageDescription: "เปรียบเทียบรถยนต์ไฟฟ้า {{brand1}} และ {{brand2}} ในประเทศไทย ราคา สเปค และฟีเจอร์ของทุกรุ่น",
    heading: "{{brand1}} vs {{brand2}} เปรียบเทียบ",
    subheading: "เปรียบเทียบสเปค ราคา และฟีเจอร์ของทุกรุ่นที่มีจำหน่าย",
    detailedComparison: "รายละเอียดการเปรียบเทียบ",
    keyDifferences: "ความแตกต่างที่สำคัญ",
    priceComparison: "เปรียบเทียบราคา",
    rangeComparison: "เปรียบเทียบระยะทางวิ่ง",
    featuresComparison: "เปรียบเทียบฟีเจอร์",
    faqTitle: "คำถามที่พบบ่อย",
    relatedComparisons: "การเปรียบเทียบที่เกี่ยวข้อง",
    availableModels: "รุ่นที่มีจำหน่าย",
    priceRange: "ช่วงราคา",
    specifications: "ข้อมูลจำเพาะ",
    price: "ราคา",
    range: "ระยะทางวิ่ง",
    batteryCapacity: "ความจุแบตเตอรี่",
    acceleration: "อัตราเร่ง",
    compareModels: "เปรียบเทียบรับ",
    compareNow: "เปรียบเทียบเลย →",
    compareDescription: "เปรียบเทียบสเปค ราคา และฟีเจอร์ระหว่าง {{brand1}} และ {{brand2}}",
    faq: {
      whichBetter: "แบรนด��ไหนดีกว่า: {{brand1}} หรือ {{brand2}}?",
      whichBetterAnswer: "ทั้ง {{brand1}} และ {{brand2}} ต่างมีจุดเด่นของตัวเอง {{brand1}} มีราคาตั้งแต่ {{price1}} ในขณะที่ {{brand2}} เริ่มต้นที่ {{price2}} การเลือกขึ้นอยู่กับความต้องการ งบประมาณ และความชอบส่วนตัวของคุณ",
      priceRange: "ราคาของ {{brand1}} และ {{brand2}} ต่างกันอย่างไร?",
      priceRangeAnswer: "{{brand1}} มีราคาเริ่มต้นที่ {{price1}} ส่วน {{brand2}} เริ่มต้นที่ {{price2}}",
      warranty: "การรับประกันของ {{brand1}} และ {{brand2}} เป็นอย่างไร?",
      warrantyAnswer: "ทั้งสองแบรนด์มีแพ็คเกจการรับประกันที่ครอบคลุม กรุณาตรวจสอบเงื่อนไขเฉพาะกับตัวแทนจำหน่ายในพื้นที่"
    },
    errors: {
      invalid: {
        title: "การเปรียบเท���ยบไม่ถูกต้อง",
        description: "กรุณาเลือกแบรนด์ที่ถูกต้องเพื่อเปรียบเทียบ"
      },
      notFound: {
        title: "ไม่พบแบรนด์",
        description: "ไม่พบแบรนด์ที่ต้องการเปรียบเทียบ"
      }
    },
    priceComparisonText: "รถยนต์ {{brand1}} มีราคาเฉลี่ยที่ {{price1}} ในขณะที่ {{brand2}} มีราคาเฉลี่ยที่ {{price2}} ความแตกต่างของราคาระหว่างสองแบรนด์คือ {{difference}}",
    rangeComparisonText: "{{brand1}} มีระยะทางวิ่งสูงสุดที่ {{range1}} กม. เทียบกับ {{brand2}} ที่ {{range2}} กม. - ต่างกัน {{difference}} กม.",
    featuresComparisonText: "{{brand1}} มาพร้อมกับ {{specs1}} ในขณะที่ {{brand2}} มาพร้อมกับ {{specs2}}"
  },
  errors: {
    invalidComparison: "การเปรียบเทียบไม่ถูกต้อง",
    invalidComparisonDesc: "กรุณาเลือกแบรนด์ที่แตกต่างกันเพื่อเปรียบเทียบ",
    brandsNotFound: "ไม่พบแบรนด์",
    brandsNotFoundDesc: "ไม่พบแบรนด์ที่คุณต้องการ กรุณาลองแบรนด์อื่น",
  },
  quiz: {
    title: "ค้นหารถไฟฟ้าที่เหมาะกับคุณ",
    consentText: "เราจะใช้คำตอบของคุณเพื่อแนะนำรถที่เหมาะสมที่สด คุณยินยอมหรือไม่?",
    agree: "ยินยอม",
    questionBudget: "งบประมาณของคุณ",
    questionRange: "ระยะทางที่ต้องการ (กม.)",
    questionType: "ประเภทรถที่ต้องการ",
    questionSeating: "จำนวนที่นั่งที่ต้องการ",
    next: "ถัดไป",
    showResults: "ดูผลการแนะนำ",
    startOver: "เริ่มใหม่",
    recommendations: "รถที่แนะนำสำหรับคุณ",
    noRecommendations: "ไม่พบรถที่ตรงตามวามต้องการ กรุณาปรับเกณฑ์ารค้นหา",
    step: "ขั้นตอนที่ {{current}} จาก {{total}}",
    select: "กรุณาเลือก",
    seatingOptions: {
      '2': "2 ที่นั่ง",
      '4': "4 ที่นั่ง",
      '5': "5 ที่นั่ง",
      '7': "7 ที่นั่ง"
    }
  },
  vehicles: {
    allVehicles: "รถยนต์ไฟฟ้าท้งหมด",
    title: "รถยนต์ไฟฟ้าในประเทศไทย | เปรียบเทียบทุกรุ่น",
    description: "ค้นหาและเปรียบเทียบรถยนต์ไฟฟ้าทั้งหมดในประเทศไทย ดูข้อมูลจำเพาะ ราคา และฟีเจอร์ของทุกรุ่น",
    foundCount: "พบ {{count}} รุ่น"
  },
  pages: {
    home: {
      title: 'รถไฟฟ้า - เว็บไซต์เปรียบเทียบรถยนต์ไฟฟ้าในประเทศไทย'
    },
    comparison: {
      title: 'เปรียบเทียบ {{brand1}} กับ {{brand2}} - รถย��ต์ไฟฟ้าในไทย'
    },
    experience: {
      title: 'ประสบการณ์การใช้งานรถยนต์ไฟฟ้า - รีวิวจากผู้ใช้จริง'
    },
    contact: {
      title: "ติดต่อเรา | RODFAIFA",
      description: "ติดต่อ RODFAIFA เรายินดีให้ความช่วยเหลือเกี่ยวกับรถยนต์ไฟฟ้า",
      subtitle: "มีคำถามเกี่ยวกับรถยนต์ไฟฟ้าในประเทศไทย? เรายินดีให้คำแนะนำ!"
    },
    quiz: {
      title: 'ค้นหารถ EV ที่ใช่สำหรับคุณ - แบบทดสอบสั้นๆ'
    },
    electricCars: {
      title: "รถยนต์ไฟฟ้าในประเทศไทย 2024 - คู่มอฉบับสมบูรณ์",
      subtitle: "ค้นพบรถยนต์ไฟฟ้ารุ่นล่าสุดในประเทศไทย พร้อมการเปรียบเทียบ ราคา และข้อมูลจำเพาะโดยละเอียด ตั้งแต่รถไฟฟ้าราคาประหยัดไปจน���ึงรถหรูระดับพรีเมียม เื่อค้นหารถยนต์ไฟฟ้าที่เหมาะกับความต้องการของคุณ",
      stats: {
        availableModels: "รุ่นที่มีจำหน่าย",
        startingPrice: "ราคาเริ่มต้น",
        maxRange: "ระยะทางสูงสุด",
        brandsAvailable: "แบรนด์ที่มีจำหน่าย"
      },
      brands: {
        title: "แบรนด์รถยนต์ไฟฟ้าในประเทศไทย | เปรียบเทียบทุกแบรนด์",
        description: "เปรียบเทียบแบรนด์รถยนต์ไฟฟ้าทั้งหมดในประเทศไทย ดูราคา สเปค และฟีเจอร์จากผู้ผลตต่างๆ",
        heading: "บรนด์รถยนต์ไฟฟ้า",
        modelCount: "{{count}} รุ่น",
        priceRange: "ช่วงราคา: {{min}} - {{max}}"
      },
      priceRanges: {
        title: "ช่วงราคารถยนต์ไฟฟ้าในประเทศไทย",
        budget: {
          title: "รถยนต���ไฟฟ้าราคาประหยัด (ต่ำก่า 1 ล้านบาท)",
          description: "รถยนต์ไฟฟ้าราคาประหยัดเริ่มต้นต่ำกว่า 1 ล้านบาท เหมาะสำหรับการับขี่ในเมืองและผู้ที่เริ่มต้นใช้รถไฟฟ้า มี {{count}} รุ่นในช่วงราคานี้"
        },
        midRange: {
          title: "รถยนต์ไฟฟ้าระดับกลาง (1-2 ล้านบาท)",
          description: "รถยนต์ไฟฟ้าระดับกลางราคา 1-2 ล้านบาท เหมาะสำหรับครอบครัวและการเดินทางไกล มี {{count}} รุ่นในช่วงราคานี้"
        },
        premium: {
          title: "รถยนต์ไฟฟ้าระดับพรีเมียม (มากกว่า 2 ล้านบาท)",
          description: "รถยนต์ไฟฟ้าระดับหรูราคามากกว่า 2 ล้านบาท เหมาะสำหรับผู้ที่ต้องการความหรูหราและสมร��ถนะสูง มี {{count}} รุ่นในช่วงราคานี้"
        }
      }
    },
    notFound: {
      title: "ไม่พบหน้าที่ค้นหา | RODFAIFA",
      description: "ไม่พบหน้าที่คุณกำลังค้นหาหรือถูกย้ายไปแล้ว",
      message: "ขออภัย เราไม่พบหน้าที่คุณกำลังค้นหา"
    }
  },
  seo: {
    brandPage: {
      title: "รถยนต์ไฟฟ้า {{brand}} ในประเทศไทย | ราคาและสเปค",
      description: "ค้นพบรถยนต์ไฟฟ้า {{brand}} ในประเทศไทย ดูราคา สเปค และฟีเจอร์",
      heading: "รถยนต์ไฟฟ้า {{brand}}",
      subheading: "{{brand}} มีรถยนต์ไฟฟ้า {{count}} รุ่นในประเทศไทย เริ่มต้นที่ {{minPrice}} เปรียบเทียบทุกรุ่นและค้นหารถที่ใช่สำหรับคุณ"
    }
  },
  footer: {
    about: "เกี่ยวกับ RODFAIFA",
    tagline: "แพลตฟอร์มเปรียบเทียบ��ถยนต์ไฟฟ้าอันดั 1 ของทย",
    quickLinks: "ลิงก์ด่วน",
    resources: "แหล่งข้อมูล",
    social: "ติดตามเรา",
    rights: "สงวนลิขสิทธิ์",
    links: {
      evGuide: "คู่มือรถยนต์ไฟฟ้าในไทย",
      compareBrands: "เปรียบเทียบแบรนด์",
      allVehicles: "รถยนต์ทั้งหมด",
      evQuiz: "แบบทดสอบรถไฟฟ้า",
      userExperiences: "ประสบการณ์ผู้ใช้",
      contactUs: "ติดต่อเรา"
    }
  },
  contact: {
    formTitle: "ติดต่อเรา",
    name: "ชื่อ",
    email: "อีเมล",
    message: "ข้อความ",
    send: "ส่งข้อความ",
    sending: "กำลังส่ง...",
    success: "ส่งข้อความสำเร็จ!",
    error: "ไม่สามารถส่งข้อความได้ กรุณาลองใหม่อีกครั้ง",
    sendAnother: "ส่งข้อความอีกครั้ง"
  }
};
