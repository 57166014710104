import React from 'react';
import { Helmet } from 'react-helmet-async';
import { EVehicle } from '../data/vehicles';

interface VehicleSchemaProps {
  vehicle: EVehicle;
}

export const VehicleSchema: React.FC<VehicleSchemaProps> = ({ vehicle }) => {
  const carSchema = {
    "@context": "https://schema.org",
    "@type": "Car",
    "name": vehicle.name,
    "brand": {
      "@type": "Brand",
      "name": vehicle.brand
    },
    "model": vehicle.name,
    "vehicleConfiguration": {
      "@type": "VehicleConfiguration",
      "fuelType": "Electricity",
      "seatingCapacity": vehicle.specs.seating,
      "cargoVolume": vehicle.specs.cargo,
      "driveWheelConfiguration": vehicle.specs.drive,
      "accelerationTime": vehicle.acceleration,
      "speed": {
        "@type": "QuantitativeValue",
        "value": vehicle.topSpeed,
        "unitCode": "KMH"
      }
    },
    "offers": {
      "@type": "Offer",
      "price": vehicle.price,
      "priceCurrency": "THB",
      "availability": "https://schema.org/InStock"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(carSchema)}
      </script>
    </Helmet>
  );
};