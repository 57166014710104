import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { vehicles } from '../data/vehicles';
import { SEOHead } from '../components/SEOHead';
import { useTranslation } from 'react-i18next';
import type { Locale } from '../i18n/seoConfig';
import { BrandComparisonContent } from '../components/BrandComparisonContent';

const CompareBrands = () => {
  const { brands } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const locale = i18n.language as Locale;
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null);

  // Get valid brands list and sort alphabetically
  const validBrands = [...new Set(vehicles.map(v => v.brand))].sort();

  // Get models count for each brand
  const getBrandModelCount = (brand: string) => 
    vehicles.filter(v => v.brand === brand).length;

  // Get price range for each brand
  const getBrandPriceRange = (brand: string) => {
    const brandVehicles = vehicles.filter(v => v.brand === brand);
    const prices = brandVehicles.map(v => v.price);
    return {
      min: Math.min(...prices).toLocaleString(),
      max: Math.max(...prices).toLocaleString()
    };
  };

  // Handle brand comparison path
  if (brands) {
    const [brand1, brand2] = brands.split('-vs-');
    
    if (!brand1 || !brand2) {
      navigate(`/${locale}/compare-brands`, { replace: true });
      return null;
    }

    const cleanBrand1 = brand1.toLowerCase();
    const cleanBrand2 = brand2.toLowerCase();

    // Validate brands
    if (!validBrands.map(b => b.toLowerCase()).includes(cleanBrand1) || 
        !validBrands.map(b => b.toLowerCase()).includes(cleanBrand2)) {
      navigate(`/${locale}/compare-brands`, { replace: true });
      return null;
    }

    const brand1Vehicles = vehicles.filter(v => v.brand.toLowerCase() === cleanBrand1);
    const brand2Vehicles = vehicles.filter(v => v.brand.toLowerCase() === cleanBrand2);

    return (
      <Layout title={t('brandComparison.pageTitle', { brand1, brand2 })}>
        <BrandComparisonContent 
          brand1={validBrands.find(b => b.toLowerCase() === cleanBrand1) || brand1}
          brand2={validBrands.find(b => b.toLowerCase() === cleanBrand2) || brand2}
          brand1Vehicles={brand1Vehicles}
          brand2Vehicles={brand2Vehicles}
        />
      </Layout>
    );
  }

  // Brand selection page with interactive grid
  return (
    <Layout title={t('brandComparison.title', { brand1: '', brand2: '' })}>
      <SEOHead
        title={t('brandComparison.pageTitle', { brand1: '', brand2: '' })}
        description={t('brandComparison.pageDescription', { brand1: '', brand2: '' })}
        canonicalPath={`/${locale}/compare-brands`}
        locale={locale}
      />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">{t('brandComparison.heading', { brand1: '', brand2: '' })}</h1>
        <p className="text-gray-600 mb-8">
          {t('brandComparison.subheading')}
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {validBrands.map(brand => (
            <div 
              key={brand}
              className={`
                relative p-6 border rounded-lg hover:shadow-lg transition-all duration-200
                ${selectedBrand === brand ? 'ring-2 ring-blue-500' : ''}
              `}
              onClick={() => setSelectedBrand(brand)}
            >
              <div className="flex flex-col">
                <span className="text-xl font-semibold text-gray-900">{brand}</span>
                <span className="text-sm text-gray-500 mt-1">
                  {getBrandModelCount(brand)} {t('common.models')}
                </span>
                <span className="text-sm text-gray-500">
                  ฿{getBrandPriceRange(brand).min} - ฿{getBrandPriceRange(brand).max}
                </span>
                
                {selectedBrand && selectedBrand !== brand && (
                  <Link
                    to={`/${locale}/compare-brands/${selectedBrand.toLowerCase()}-vs-${brand.toLowerCase()}`}
                    className="mt-4 inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                  >
                    {t('brandComparison.compareModels')} {selectedBrand}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>

        {selectedBrand && (
          <div className="fixed bottom-4 left-4 right-4 bg-white p-4 rounded-lg shadow-lg border">
            <div className="flex items-center justify-between">
              <span>{t('common.selected')}: {selectedBrand}</span>
              <button 
                onClick={() => setSelectedBrand(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                {t('common.clearSelection')}
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CompareBrands;