import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Instagram, Twitter, BookOpen } from 'lucide-react'; // Import icons

const Footer: React.FC = () => {
  const { t } = useTranslation();
  
  const socialLinks = [
    {
      name: 'Instagram',
      href: 'https://instagram.com/rodfaifa',
      icon: Instagram,
      username: '@rodfaifa'
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/evjab',
      icon: Twitter,
      username: '@evjab'
    },
    {
      name: 'Medium',
      href: 'https://medium.com/@rodfaifa',
      icon: BookOpen,
      username: '@rodfaifa'
    }
  ];

  return (
    <footer className="bg-white border-t border-gray-200 mt-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="font-bold text-lg mb-4">{t('footer.about')}</h3>
            <p className="text-gray-600">
              RODFAIFA.com - {t('footer.tagline')}
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">{t('footer.quickLinks')}</h3>
            <ul className="space-y-2">
              <li><Link to="/electric-car-thailand" className="text-gray-600 hover:text-gray-900">{t('footer.links.evGuide')}</Link></li>
              <li><Link to="/compare-brands" className="text-gray-600 hover:text-gray-900">{t('footer.links.compareBrands')}</Link></li>
              <li><Link to="/vehicles" className="text-gray-600 hover:text-gray-900">{t('footer.links.allVehicles')}</Link></li>
              <li><Link to="/quiz" className="text-gray-600 hover:text-gray-900">{t('footer.links.evQuiz')}</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">{t('footer.resources')}</h3>
            <ul className="space-y-2">
              <li><Link to="/experience" className="text-gray-600 hover:text-gray-900">{t('footer.links.userExperiences')}</Link></li>
              <li><Link to="/contact" className="text-gray-600 hover:text-gray-900">{t('footer.links.contactUs')}</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">{t('footer.social')}</h3>
            <div className="space-y-3">
              {socialLinks.map((social) => (
                <a
                  key={social.name}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
                >
                  <social.icon className="h-5 w-5" />
                  <span>{social.username}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 mt-8 pt-8 text-center text-gray-600">
          <p>© {new Date().getFullYear()} RODFAIFA.com - {t('footer.rights')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 