import React from 'react';
import { useTranslation } from 'react-i18next';
import { Languages } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LanguageSwitch() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'th' : 'en';
    
    // Change the language in i18n
    i18n.changeLanguage(newLang);

    // Update the URL to reflect the new language
    const currentPath = location.pathname;
    const pathWithoutLang = currentPath.replace(/^\/(en|th)/, '');
    navigate(`/${newLang}${pathWithoutLang}${location.search}`);
  };

  return (
    <button
      onClick={toggleLanguage}
      className="flex items-center gap-2 px-3 py-2 rounded-lg bg-gray-100 hover:bg-gray-200"
    >
      <Languages className="w-4 h-4" />
      <span className="text-sm font-medium">{i18n.language.toUpperCase()}</span>
    </button>
  );
}